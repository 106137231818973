import React from "react"
import { Box, Flex, Link } from "@chakra-ui/react";

import logo from '../assets/images/logo.png';
import lupa from '../assets/images/lupa.png';
import igLogo from '../assets/images/RRSS-ig.png'
import twLogo from '../assets/images/RRSS-tw.png'
import fbLogo from '../assets/images/RRSS-fb.png'
import spotifyLogo from '../assets/images/RRSS-spotify.png'
import ytLogo from '../assets/images/RRSS-yt.png'

const MenuItems = (props) => (
  <div className={"menu-item-container"}>
    <Link 
      href={props.href}
      mt={{ base: 4, md: 0 }} 
      mr={6} 
      display="block" 
      className={"menu-item"}>
      {props.children}
    </Link>
  </div>
);

function Header() {

  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);
  
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="FFF"
      color="black"
    >
      <Flex mr={0} className={"logo-container"}>
        <a href="/"><img src={logo} className={"logo-oleada"} alt="Oleada"/></a>
      </Flex>

      <Box className={"header-menu-svg"} display={{ base: "block", md: "none" }} onClick={handleToggle}>
        <svg fill="black" width="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ base: show ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "750px" }}
        alignItems="flex-start"
      >
        <MenuItems 
          href="/analisis"
          text="QUÉ CARAJO ESTÁ PASANDO">
            ANÁLISIS
        </MenuItems>

        <MenuItems 
          href="/ensayos"
          text="REFLEXIONES PARA PENSAR">
            ENSAYOS
        </MenuItems>

        <MenuItems 
          href="/entrevistas"
          text="REPORTAJES Y PERFILES">
            ENTREVISTAS
        </MenuItems>

        <MenuItems 
          href="/cronicas"
          text="TODO LO QUE SERÍA NO-FICCIÓN">
            CRÓNICAS
        </MenuItems>

        <MenuItems 
          href="/ficcion"
          text="TODO LO QUE SERÍA LITERATURA">
            FICCIÓN
        </MenuItems>

        <MenuItems 
          href="/audiovisual"
          text="VIDEITOS LINDOS Y DOCUMENTALES CORTOS">
            AUDIOVISUAL
        </MenuItems>

        <MenuItems 
          href="/podcast"
          text="ES COMO LA RADIO, PERO NO ES EN VIVO">
            PODCAST
        </MenuItems>
      </Box>

      <Box
        display={{ base: "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
        className={"header-search"}
      >
        <a href="https://www.instagram.com/revistaoleada/" 
          className="rrss-link"
          target="_blank"
          rel="noreferrer">
            <img src={igLogo} className={"rrss-img"} alt="Instagram"/>
        </a>
        <a href="https://twitter.com/revistaoleada_" 
          className="rrss-link"
          target="_blank"
          rel="noreferrer">
            <img src={twLogo} className={"rrss-img"} alt="Twitter"/>
        </a>
        <a href="https://www.facebook.com/OleadaRevistaDigital/"
          className="rrss-link"
          target="_blank"
          rel="noreferrer">
            <img src={fbLogo} className={"rrss-img"} alt="Facebook"/>
        </a>
        <a href="https://open.spotify.com/show/4lBzic3bBEmKDtgct7HDz4?si=x2hMcyzUQsOSIdGMxYrIPw" 
          className="rrss-link"
          target="_blank"
          rel="noreferrer">
            <img src={spotifyLogo} className={"rrss-img"} alt="Spotify"/>
        </a>
        <a href="https://www.youtube.com/channel/UC2cE9wZPfHCBRnefrVOa39w" 
          className="rrss-link"
          target="_blank"
          rel="noreferrer">
            <img src={ytLogo} className={"rrss-img"} alt="RSS"/>
        </a>
        <form action="/busqueda" method="GET" className={"search-bar"}>
          <input type="text" name="titulo" className={"header-search-input"}/>
          <button className={"search-button"}>
            <img src={lupa} className={"search-button-img"} alt=""/>
          </button>
        </form>
      </Box>
    </Flex>
  );
}
export default Header;
