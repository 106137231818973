import React, { Component } from 'react';

import logo from '../../assets/images/logo.png';
import otrosLogos from '../../assets/images/otros-logos.png';
import igLogo from '../../assets/images/RRSS-ig.png'
import twLogo from '../../assets/images/RRSS-tw.png'
import fbLogo from '../../assets/images/RRSS-fb.png'
import spotifyLogo from '../../assets/images/RRSS-spotify.png'
import ytLogo from '../../assets/images/RRSS-yt.png'

class Footer extends Component {

    render() {
        return(
            <div className={"footer"}>
                <div className={"footer-logo"}>
                    <a href="https://www.oleada.com.ar">
                        <img src={logo} className={"footer-logo-img"} alt="Oleada"/>
                    </a>
                    <br/>
                    <div className={"footer-rrss"}>
                        <a className={"footer-contacto-link"} href="/contacto">Contactanos</a> <br/>
                        <a href="https://www.instagram.com/revistaoleada/" 
                            className="rrss-link"
                            target="_blank"
                            rel="noreferrer">
                            <img src={igLogo} className={"rrss-img"} alt="Instagram"/>
                        </a>
                        <a href="https://twitter.com/revistaoleada_" 
                            className="rrss-link"
                            target="_blank"
                            rel="noreferrer">
                            <img src={twLogo} className={"rrss-img"} alt="Twitter"/>
                        </a>
                        <a href="https://www.facebook.com/OleadaRevistaDigital/" 
                            className="rrss-link"
                            target="_blank"
                            rel="noreferrer">
                            <img src={fbLogo} className={"rrss-img"} alt="Facebook"/>
                        </a>
                        <a href="https://open.spotify.com/show/4lBzic3bBEmKDtgct7HDz4?si=x2hMcyzUQsOSIdGMxYrIPw" 
                            className="rrss-link"
                            target="_blank"
                            rel="noreferrer">
                            <img src={spotifyLogo} className={"rrss-img"} alt="Spotify"/>
                        </a>
                        <a href="https://www.youtube.com/channel/UC2cE9wZPfHCBRnefrVOa39w" 
                            className="rrss-link"
                            target="_blank"
                            rel="noreferrer">
                            <img src={ytLogo} className={"rrss-img"} alt="RSS"/>
                        </a>
                    </div>
                </div>

                {/* Desktop */}
                <div className={"footer-links"}>
                    <a className={"footer-contacto-link"} href="/contacto">Contactanos</a> <br/>
                    <a 
                        href="https://www.instagram.com/revistaoleada/" 
                        className="rrss-link">
                        <img src={igLogo} className={"rrss-img"} alt="Instagram"/>
                    </a>
                    <a 
                        href="https://twitter.com/revistaoleada_" 
                        className="rrss-link">
                        <img src={twLogo} className={"rrss-img"} alt="Twitter"/>
                    </a>
                    <a 
                        href="https://www.facebook.com/OleadaRevistaDigital/" 
                        className="rrss-link">
                        <img src={fbLogo} className={"rrss-img"} alt="Facebook"/>
                    </a>
                    <a 
                        href="https://open.spotify.com/show/4lBzic3bBEmKDtgct7HDz4?si=x2hMcyzUQsOSIdGMxYrIPw" 
                        className="rrss-link">
                        <img src={spotifyLogo} className={"rrss-img"} alt="Spotify"/>
                    </a>
                    <a 
                        href="https://www.youtube.com/channel/UC2cE9wZPfHCBRnefrVOa39w" 
                        className="rrss-link">
                        <img src={ytLogo} className={"rrss-img"} alt="RSS"/>
                    </a>
                </div>
                <div className={"footer-other-logos"}>
                    <span className="footer-logos-title">EN COLABORACIÓN CON</span>
                    <br/>
                    <img src={otrosLogos} alt="Instituto Democracia, OCEPP, Observatorio de Géneros y Políticas Públicas, CEDESU, JJ Circuito Cultural"/>
                </div>

                {/* Mobile */}
                <div className={"footer-menu"}>
                    <a href="/analisis">Análisis</a> <br/>
                    <a href="/ensayos">Ensayos</a> <br/>
                    <a href="/entrevistas">Entrevistas</a> <br/>
                    <a href="/cronicas">Crónicas</a> <br/>
                    <a href="/ficcion">Ficción</a> <br/>
                    <a href="/audiovisual">Audiovisual</a> <br/>
                    <a href="/podcast">Podcast</a>
                </div>
            </div>
        );
    }

}

export default Footer;
