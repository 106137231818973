import React from "react"
import Header from "./header"
import Footer from './footer';
import favicon from '../assets/images/favicon.png';
import {Helmet} from "react-helmet";

import "../assets/style.css"

const Layout = ({ children }) => (
  <>
    <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    </Helmet>
    <Header />
    {children}
    <Footer/>
  </>
)

export default Layout
